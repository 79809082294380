import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DBValidators } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { ConditionalRequiredSettings, OAPValidators } from 'src/app/core/validators.directive';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from '../../core/base.component';
import { ApplicationDataService } from '../../shared/services/application-data.service';
import { NavButtonBarService } from '../../shared/services/nav-button-bar.service';

@Component({
  selector: 'app-personal-outgoings',
  templateUrl: './personal-outgoings.component.html',
  styleUrls: ['./personal-outgoings.component.scss']
})
export class PersonalOutgoingsComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  errors: any = {
    lebensHaltungsKosten: [
      {
        rule: 'required',
        message: 'personal-outgoings.monthly-costs.cost-of-living.hint'
      }
    ],
    sonstAusgabenBetrag: [
      {
        rule: 'required',
        message: 'personal-outgoings.monthly-costs.other-expenses-sum.hint'
      }
    ],
    sonstAusgabenText: [
      {
        rule: 'required',
        message: 'personal-outgoings.monthly-costs.other-expenses-description.hint'
      }
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
  }
  ngOnInit() {
    super.ngOnInit();
    this.headerService.changeText('personal-additional-outgoings.headline');
    this.formSettings();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_outgoings", "personal data");
  }

  formSettings() {
    const requiredSetting: ConditionalRequiredSettings[] = new Array();
    requiredSetting.push({
      controlNames: ['sonstAusgabenBetrag'],
      andConditions: [{ conditionalControlName: 'sonstAusgabenText' }]
    });
    requiredSetting.push({
      controlNames: ['sonstAusgabenText'],
      andConditions: [{ conditionalControlName: 'sonstAusgabenBetrag' }]
    });

    this.formValidation = this.formBuilder.group(
      {
        sonstAusgabenBetrag: new FormControl(this.sonstAusgabenBetrag),
        sonstAusgabenText: new FormControl(this.sonstAusgabenText),
        lebensHaltungsKosten: new FormControl(this.lebensHaltungsKosten, DBValidators.required())
      },
      {
        validator: OAPValidators.conditionalRequiredValuesFormValidator(requiredSetting)
      }
    );
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
  get eigenmieteKalt() {
    return this.haushaltsrechnung.ausgaben.eigenmieteKalt;
  }
  set eigenmieteKalt(eigenmieteKalt: number) {
    this.haushaltsrechnung.ausgaben.eigenmieteKalt = +eigenmieteKalt;
  }
  get eigenmieteNebenkosten() {
    return this.haushaltsrechnung.ausgaben.eigenmieteNebenkosten;
  }
  set eigenmieteNebenkosten(eigenmieteNebenkosten: number) {
    this.haushaltsrechnung.ausgaben.eigenmieteNebenkosten = +eigenmieteNebenkosten;
  }
  get praemieKrankenversicherung() {
    return this.haushaltsrechnung.ausgaben.praemieKrankenversicherung;
  }
  set praemieKrankenversicherung(praemieKrankenversicherung: number) {
    this.haushaltsrechnung.ausgaben.praemieKrankenversicherung = +praemieKrankenversicherung;
  }
  get unterhaltszahlungen() {
    return this.haushaltsrechnung.ausgaben.unterhaltszahlungen;
  }
  set unterhaltszahlungen(unterhaltszahlungen: number) {
    this.haushaltsrechnung.ausgaben.unterhaltszahlungen = +unterhaltszahlungen;
  }
  get sonstAusgabenBetrag() {
    return this.haushaltsrechnung.ausgaben.sonstAusgabenBetrag;
  }
  set sonstAusgabenBetrag(sonstAusgabenBetrag: number) {
    this.haushaltsrechnung.ausgaben.sonstAusgabenBetrag = +sonstAusgabenBetrag;
  }
  get sonstAusgabenText() {
    return this.haushaltsrechnung.ausgaben.sonstAusgabenText;
  }
  set sonstAusgabenText(sonstAusgabenText: string) {
    this.haushaltsrechnung.ausgaben.sonstAusgabenText = sonstAusgabenText;
  }
  get lebensHaltungsKosten() {
    return this.haushaltsrechnung.ausgaben.lebensHaltungsKosten;
  }
  set lebensHaltungsKosten(lebensHaltungsKosten: number) {
    this.haushaltsrechnung.ausgaben.lebensHaltungsKosten = +lebensHaltungsKosten;
  }
  get freiwilligeSparLeistungen() {
    return this.haushaltsrechnung.ausgaben.freiwilligeSparLeistungen;
  }
  set freiwilligeSparLeistungen(freiwilligeSparLeistungen: number) {
    this.haushaltsrechnung.ausgaben.freiwilligeSparLeistungen = +freiwilligeSparLeistungen;
  }
 
  get kfzKosten() {
    return this.haushaltsrechnung.ausgaben.kfzKosten;
  }
  set kfzKosten(kfzKosten: number) {
    this.haushaltsrechnung.ausgaben.kfzKosten = +kfzKosten;
  }
  get kfzAnzahl() {
    return this.haushaltsrechnung.ausgaben.kfzAnzahl;
  }
  set kfzAnzahl(kfzAnzahl: number) {
    this.haushaltsrechnung.ausgaben.kfzAnzahl = +kfzAnzahl;
  }
}
