import { Immobilie } from './immobilie';

export class Vermoegen {
  kontoguthaben: number;
  kurswert: number;
  bausparguthaben: number;
  sparplane: number;
  rueckkaufswertLebensversicherungen: number;
  eigeneImmobilie: Immobilie[];
  vermieteteImmobilie: Immobilie[];
  unbebautesGrundstueck: Immobilie[];

  constructor(vermoegen?: Vermoegen) {
    this.kontoguthaben = vermoegen ? vermoegen.kontoguthaben : null;
    this.kurswert = vermoegen ? vermoegen.kurswert : null;
    this.bausparguthaben = vermoegen ? vermoegen.bausparguthaben : null;
    this.rueckkaufswertLebensversicherungen = vermoegen
      ? vermoegen.rueckkaufswertLebensversicherungen
      : null;
    this.eigeneImmobilie = vermoegen && vermoegen.eigeneImmobilie ? vermoegen.eigeneImmobilie : [];
    this.vermieteteImmobilie = vermoegen && vermoegen.vermieteteImmobilie ? vermoegen.vermieteteImmobilie : [];
    this.unbebautesGrundstueck = vermoegen && vermoegen.unbebautesGrundstueck
      ? vermoegen.unbebautesGrundstueck
      : [];
    this.sparplane = vermoegen ? vermoegen.sparplane : null;
  }
}
