import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { DBValidators, DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Immobilie } from 'src/app/shared/model/immobilie';
import { AdobeApiService } from 'src/app/shared/services/adobe-api.service';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

import { BaseComponent } from './../../core/base.component';

@Component({
  selector: 'app-personal-fortune',
  templateUrl: './personal-fortune.component.html',
  styleUrls: ['./personal-fortune.component.scss']
})
export class PersonalFortuneComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  public errors: any = {
    wert: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Wert'
      }
    ],
    plz: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'PLZ'
      },
      {
        rule: 'pattern',
        message: 'general.zip-code.format.hint'
      }
    ],
    ort: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Ort'
      },
      {
        rule: 'minlength',
        message: 'general.field.minlength.hint',
        fieldName: 'Ort',
        min: 2
      }
    ],
    strasse: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Strasse'
      },
      {
        rule: 'minlength',
        message: 'general.field.minlength.hint',
        fieldName: 'Strasse',
        min: 2
      }
    ],
    hausnummer: [
      {
        rule: 'required',
        message: 'general.field.required.hint',
        fieldName: 'Hausnummer'
      },
      {
        rule: 'pattern',
        message: 'general.house-number.format.hint'
      }
    ]
  };
  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private adobe: AdobeApiService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService);
    this.toggleOpenArray = [false, false, false];
  }
  ngOnInit() {
    super.ngOnInit();
    this.headerService.changeText('personal-fortune.headline');
    this.formSettings();
    this.translateErrors(this.errors);
    this.adobe.pushAdobeEvent("Personal_fortune", "personal data");
  }
  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  // public template methods
  public getProperties(prefix: string) {
    return this.formValidation.controls[prefix] as FormArray;
  }
  public addProperty(prefix: string) {
    if (!this.formValidation.invalid) {
      this[prefix].push(new Immobilie());
      this.addPropertyFormControlsFor(prefix, this[prefix].length - 1);
    } else {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
    }
  }
  public removeProperty(prefix: string, index: number) {
    this[prefix].splice(index, 1);
    this.removePropertyFormControlsFor(prefix, index);
  }

  // private form builder methods
  private formSettings() {
    this.formValidation = this.formBuilder.group({
      eigeneImmobilie: new FormArray([]),
      vermieteteImmobilie: new FormArray([]),
      unbebautesGrundstueck: new FormArray([])
    });
    this.buildAllFormGroups();
  }
  private buildAllFormGroups() {
    this.initFormGroupFor('eigeneImmobilie');
    this.initFormGroupFor('vermieteteImmobilie');
    this.initFormGroupFor('unbebautesGrundstueck');
  }
  private initFormGroupFor(prefix: string) {
    this[prefix].forEach(property => this.addPropertyFormControlsFor(prefix, this[prefix].indexOf(property)));
  }

  private removePropertyFormControlsFor(prefix: string, index: number) {
    (this.formValidation.controls[prefix] as FormArray).removeAt(index);
  }

  private addPropertyFormControlsFor(prefix: string, index: number) {
    (this.formValidation.controls[prefix] as FormArray).push(
      this.formBuilder.group({
        wert: [this[prefix][index].wert, DBValidators.required()],
        plz: [this[prefix][index].plz, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.pattern(/^[0-9]{5,5}$/)])],
        ort: [this[prefix][index].ort, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.minLength(2)])],
        strasse: [this[prefix][index].strasse, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.minLength(2)])],
        hausnummer: [this[prefix][index].hausnummer, DBValidatorsUtil.chain([DBValidators.required(), DBValidators.pattern(/^[a-zA-Z0-9/\\-]*$/)])]
      })
    );
  }
  // getter & setter

  get kontoguthaben() {
    return this.vermoegen.kontoguthaben;
  }
  set kontoguthaben(kontoguthaben: number) {
    this.vermoegen.kontoguthaben = +kontoguthaben;
  }
  get kurswert() {
    return this.vermoegen.kurswert;
  }
  set kurswert(kurswert: number) {
    this.vermoegen.kurswert = +kurswert;
  }
  get bausparguthaben() {
    return this.vermoegen.bausparguthaben;
  }
  set bausparguthaben(bausparguthaben: number) {
    this.vermoegen.bausparguthaben = +bausparguthaben;
  }
  get rueckkaufswertLebensversicherungen() {
    return this.vermoegen.rueckkaufswertLebensversicherungen;
  }
  set rueckkaufswertLebensversicherungen(rueckkaufswertLebensversicherungen: number) {
    this.vermoegen.rueckkaufswertLebensversicherungen = +rueckkaufswertLebensversicherungen;
  }
  get eigeneImmobilie(): Immobilie[] {
    return this.vermoegen.eigeneImmobilie;
  }
  get unbebautesGrundstueck(): Immobilie[] {
    return this.vermoegen.unbebautesGrundstueck;
  }
  get vermieteteImmobilie() {
    return this.vermoegen.vermieteteImmobilie;
  }
  get vermoegen() {
    return this.haushaltsrechnung.vermoegen;
  }
  
  get sparplane() {
    return this.vermoegen.sparplane; 
  }
  set sparplane(sparplane: number){
    this.vermoegen.sparplane =+sparplane;
  }
}
